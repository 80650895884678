import React, { useEffect, useRef, useState } from "react";
import { Breadcum } from "../../../../components/breadcum";
import { useHistory, useParams } from "react-router-dom";
import FormBuilder from "../../../../components/form-builder";
import { toast } from "react-toastify";
import { GET_BOARD_FILTERS_LIST, GET_MEDIUM_FILTERS_LIST, GET_STANDARD_FILTERS_LIST, GET_SUBJECT_FILTERS_LIST } from "../../../../app/service/abhyas-worksheet-filter.service";
import { GET_CONTENT_DATA } from "../../../../app/service/content-module/dashboard-service";
import { KaryakartaQuestionsList } from "./questions-list";
import { KaryakartaTestsList } from "./tests-list";
import { KaryakartaSystemHours } from "./system-hours";
import { KaryakartaWorksheetList } from "./worksheet-list";

export const KaryakartaInfo = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [selectedItem, setSelectedItem] = useState<string | number>("");
    const [filterData, setFilterData] = useState<any>({});
    const [showData, setShowData] = useState<string>("");
    const [userInfo, setUserInfo] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(false);
    let { userCode, userName, userRole } = useParams<{ userCode: any, userName: string, userRole: string }>();
    const [loadData, setLoadData] = useState<boolean>(true);
    const [listCards, setListCards] = useState<any>([]);
    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);
    const history = useHistory();
    const formRef = useRef<HTMLFormElement | null>(null);
    const cardsArray = [
        {
            name: "Total Questions Created",
            code: "question",
            value: "0"
        },
        {
            name: "Total Questions Checked",
            code: "checked",
            value: "0"
        },
        {
            name: "Total Tests Created",
            code: "test",
            value: "0"
        },
        {
            name: "Total Worksheets Created",
            code: "worksheets",
            value: "0"
        },
        {
            name: "Total Questions Uploaded",
            code: "upload",
            value: "0"
        },
        {
            name: "Total Hours on System",
            code: "hours",
            value: "0"
        },

    ]
    const items = [
        { value: 1, label: "Today" },
        { value: 2, label: "Yesterday" },
        { value: 3, label: "Last 7 days" },
        { value: 4, label: "Last 30 days" },
        { value: 5, label: "Last year" },
        { value: 6, label: "Custom" },

    ];

    useEffect(() => {
        getDisplayRoleWise();
        fetchData();
        getBoardData();
    }, []);
    const getDisplayRoleWise = () => {
        if (userRole === "DATA_ENTRY_OPERATOR") {
            let preArray = cardsArray?.filter((item) => item?.code === "upload" || item?.code === "hours")
            setListCards(preArray);
        } else if (userRole === "CONTENT_VALIDATOR") {
            let preArray = cardsArray?.filter((item) => item?.code === "checked" || item?.code === "hours")
            setListCards(preArray);

        } else if (userRole === "SUPER_ADMIN" || userRole === "IAS_ADMIN") {
            setListCards(cardsArray);

        }
        else {
            let preData = cardsArray?.filter((item) => item?.code !== "upload" && item?.code !== "checked")
            setListCards(preData);
        }
    }
    const fetchData = async () => {
        setLoading(true);
        try {
            const payLoad = {
                user_code: userCode,
                dashboard_stats: false,
                dashboard_internal_stats: true
            }
            const res: any = await GET_CONTENT_DATA(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    setUserInfo(res);
                }
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);

        }
    }
    const getBoardData = () => {
        GET_BOARD_FILTERS_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                }
            }
        });
    }

    const onChangeBoard = (e: any) => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_MEDIUM_FILTERS_LIST(e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        const data = res['rows'];
                        setMediumList(data);
                    }
                }
            });
        }
    }
    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        if (e) {
            GET_STANDARD_FILTERS_LIST(filterData?.board_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setStandardList(data);
                    }
                }
            });
        }
    }
    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        if (e) {
            GET_SUBJECT_FILTERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, e).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                    } else {
                        const data = res['rows'];
                        setSubjectList(data);
                    }
                }
            });
        }
    }
    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
        handleLoadData();
    };
    const handleLoadData = () => {
        setLoadData(false);
        setTimeout(() => {
            setLoadData(true);
        }, 100);
    }
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (item: any) => {
        setSelectedItem(item.label);
        let preData = { ...filterData }
        preData.custom = item.label?.toLowerCase()
        setFilterData(preData);
        setIsOpen(false);
        handleLoadData();
    };
    const handleBack = () => {
        history.push(`/dashboard/content/karyakarta/list`)
    }
    const handleView = (index: number, viewData: string) => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
        setShowData(viewData);
        if (index !== activeCardIndex) {
            setFilterData({});
            if (formRef?.current) {
                formRef.current.reset();
            }
        }
    }
    const showFilters = () => {
        if (showData === "hours") {
            return false;
        }
        else {
            return true;
        }

    }
    const capitalizeFirstLetterOnly = (input: string): string => {
        return input
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const getCountValue = (code: string) => {
        switch (code) {
            case "question":
                return userInfo?.total_questions_created?.length || 0;
            case "test":
                return userInfo?.total_tests?.length || 0;
            case "worksheets":
                return userInfo?.total_worksheets?.length || 0;
            case "hours":
                let totalSeconds = Number(userInfo?.total_hours) || 0; // Ensure it's a valid number
                let hours = Math.floor(totalSeconds / 3600); // Extract hours
                let minutes = Math.floor((totalSeconds % 3600) / 60); // Extract minutes
                let seconds = totalSeconds % 60; // Extract remaining seconds

                // Ensure consistent formatting by conditionally including components
                let formattedTime =
                    (hours > 0 ? `${hours}hr ` : "") +
                    (minutes > 0 ? `${minutes}m ` : "") +
                    (seconds > 0 ? `${seconds}s` : "");

                return formattedTime.trim() || "0s";
            case "upload":
                return userInfo?.total_questions?.length || 0;
            case "checked":
                return userInfo?.total_questions_validated?.length || 0;
            default:
                return 0;
        }
    }
    const getTextClor = (code: string) => {
        switch (code) {
            case "question":
                return "gn_karya";
            case "test":
                return "test_karya";
            case "worksheets":
                return "test_karya";
            case "hours":
                return "hours_karya";
            default:
                return "gn_karya";
        }
    }
    return (
        <div className=" border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <div className="bg_gray_ligth">
                        <p className="p-2 f16 text-primary cursor-pointer " onClick={handleBack}>Karyakarta Analysis</p>
                    </div>
                    <h5 className="mb-4 text-center">{userName} ( {capitalizeFirstLetterOnly(userRole)} ) </h5>
                    <div className="karya_display_bx position-relative text-center">
                        {listCards?.map((item: any, i: number) => (
                            <div className="" key={i} >
                                <div className="karya_bx text-center " onClick={() => handleView(i, item?.code)}>
                                    <h1 className={`text_uline mb-0  ${getTextClor(item?.code)}`}>{getCountValue(item?.code)}</h1>
                                    <h6 className="mb-0 f16">{item?.name}</h6>
                                </div>
                                {(showData === item?.code && activeCardIndex !== null) && <p className="mb-0 ms-3 trapezoid">
                                </p>}
                                {(showData === item?.code && activeCardIndex !== null) && <div className="karya-table-container ">
                                    <div className="filters_karya mx-3 p-3">
                                        <FormBuilder onUpdate={handleInput}>
                                            <form ref={formRef}>
                                                <div className="karya_filter_list">
                                                    <div className="d-block">
                                                        <div>
                                                            <label className="form-label mb-0 f14">Date Range</label>
                                                        </div>
                                                        <div className="select-dropdown">
                                                            <button
                                                                type="button"
                                                                role="button"
                                                                className="select-dropdown__button"
                                                                onClick={toggleDropdown}
                                                            >
                                                                <span>{selectedItem || "Custom"}</span>
                                                                <i className="zmdi zmdi-chevron-down">&#129171;</i>
                                                            </button>
                                                            <ul
                                                                className={`select-dropdown__list ${isOpen ? "active" : ""}`}
                                                                onClick={(e) => e.stopPropagation()}
                                                            >
                                                                {items.map((item) => (
                                                                    <li
                                                                        key={item.value}
                                                                        data-value={item.value}
                                                                        className="select-dropdown__list-item"
                                                                        onClick={() => handleSelect(item)}
                                                                    >
                                                                        {item.label}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label mb-0 f14">From Date
                                                        </label>
                                                        <input type="date" className='form-control f14 boarder_org_cl' name="from_date" onKeyDown={(event) => {
                                                            event.preventDefault();
                                                        }} />
                                                    </div>
                                                    <div className="">
                                                        <label className="form-label mb-0 f14">To Date
                                                        </label>
                                                        <input type="date" className='form-control f14 boarder_org_cl' name="to_date" onKeyDown={(event) => {
                                                            event.preventDefault();
                                                        }} />
                                                    </div>
                                                    {showFilters() && <>
                                                        <div className='mb-4'>
                                                            <label className="f14">Board</label>
                                                            <select name="board_uuid" id="board_uuid" className="form-select f14 boarder_org_cl" onChange={(e) => { onChangeBoard(e.target.value) }}>
                                                                <option value="">Select Board</option>
                                                                {boardsList?.map((name: any, i: number) => (
                                                                    <option key={i} value={name?.uuid}>
                                                                        {name?.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label>Medium</label>
                                                            <select name="medium_uuid" id="medium_uuid" className="form-select f14 boarder_org_cl" onChange={(e) => { onChangeMedium(e.target.value) }}>
                                                                <option value="">Select Medium</option>
                                                                {mediumList?.map((name: any, i: number) => (
                                                                    <option key={i} value={name?.uuid}>
                                                                        {name?.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label className="f14">Standard</label>
                                                            <select name="standard_uuid" id="standard_uuid" className="form-select f14  boarder_org_cl" onChange={(e) => { onChangeStandard(e.target.value) }}>
                                                                <option value="">Select Standard</option>
                                                                {standardList?.map((name: any, i: number) => (
                                                                    <option key={i} value={name?.uuid}>
                                                                        {name?.standard}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div>
                                                            <label className="f14">Subject</label>
                                                            <select name="subject_uuid" id="subject_uuid" className="form-select f14  boarder_org_cl">
                                                                <option value="">Select Subject</option>
                                                                {subjectList?.map((name: any, i: number) => (
                                                                    <option key={i} value={name?.uuid}>
                                                                        {name?.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {showData !== "test" && <div>
                                                            <label className="f14">Difficulty Level</label>
                                                            <select name="difficulty_level" id="difficulty_level" className="form-select f14 boarder_org_cl">
                                                                <option value="">All</option>
                                                                <option value="EASY">Easy</option>
                                                                <option value="MEDIUM">Medium</option>
                                                                <option value="HARD">Hard</option>

                                                            </select>
                                                        </div>}
                                                    </>}
                                                </div>
                                            </form>
                                        </FormBuilder>
                                        {loadData && <div>
                                            {(showData === "question" || showData === "upload" || showData === "checked") &&
                                                <KaryakartaQuestionsList filterData={filterData} userCode={userCode} userAccess={showData} />}
                                            {showData === "test" && <KaryakartaTestsList filterData={filterData} userCode={userCode} />}
                                            {showData === "worksheets" && <KaryakartaWorksheetList filterData={filterData} userCode={userCode} />}
                                            {showData === "hours" && <KaryakartaSystemHours filterData={filterData} userCode={userCode} />}
                                        </div>}
                                    </div>
                                </div>}
                            </div>
                        ))}
                    </div>
                </div>}
        </div>
    )
}