import API from "../utility/axios";
const apiUrl = "/api/v2";
export const MATHS_REPORT_DOWNLOAD = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/module/html/to/pdf`, {
    params: data,
  });
};
export const FILE_REPORT_DOWNLOAD = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/get/presigned/pdf/url`, {
    params: data,
  });
};
