import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "../../../../components/data-table";
import { FormControlError, FormField, FormValidators } from "../../../../components/form-builder/model/form-field";
import { toast } from "react-toastify";
import { FormValidator } from "../../../../components/form-builder/validations";
import FormBuilder from "../../../../components/form-builder";
import moment from "moment";
import { CREATE_WORKSHEET, DELETE_WORKSHEET, GET_WORKSHEET_BY_UUID, GET_WORKSHEET_FILTER, GET_WORKSHEET_LIST, UPDATE_WORKSHEET } from "../../../../app/service/abhyas-worksheet.service";
import { ContentAbhyasWorksheetDataGridCols } from "./worksheet-data-grid-cols";
import { GET_CONTENT_RANDOM_QUESTIONS } from "../../../../app/service/content-module/question-service";
import ContentPreviewQuestions from "../preview-popup";
import { GET_TEST_DATA } from "../../../../app/service/content-module/test-service";

interface Props {
    isLinked?: string;
    updateList?: (e: any) => void;
    selectTest?: ((e: string) => void);
    filterData?: any;
    isLoading?: boolean;
    setTestType: any;
    setCanShowTestQuestions: any;
    setCreateTestData: any;

}
export const ContentAddAbhyaasWorksheet: React.FC<Props> = (props) => {
    const [filterData, setFilterData] = useState<any>(props?.filterData);
    const [worksheetList, setWorksheetList] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageArray, setPageNumbers] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const [canShowPopup, setCanShowPopup] = useState<boolean>(false);
    const [worksheetUuid, setWorksheetUuid] = useState<string>("0");
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const [currentFormData, setCurrentFormData] = useState<any>({});
    const [formData, setFormData] = useState<any>([]);
    const [formValidationErrors, setFormValidationErrors] = useState<FormControlError[]>([]);
    const [nameError, setNameError] = useState('');
    const [preparedSearchData, setPreparedSearchData] = useState<any>({
        mode: "ABHYAAS",
        sorting_field: "number",
        sorting_type: "asc",
    });
    const medimForm = useRef<any>({});
    const searchForm = useRef<any>({});
    const [searchData, setSearchData] = useState<any>({});
    const [typeError, setTypeError] = useState<boolean>(false);
    const [levelError, setLevelError] = useState<boolean>(false);
    const [numberError, setNumberError] = useState<string>("");
    const boardUuid = props.filterData?.board_uuid;
    const mediumUuid = props.filterData?.medium_uuid;
    const standardUuid = props.filterData?.standard_uuid;
    const subjectUuid = props.filterData?.subject_uuid;
    const chapterUuid = props.filterData?.chapter_uuid;
    const topicUuid = props.filterData?.topic_uuid;
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const FormValidations = [
        new FormField('type', [FormValidators.REQUIRED]),
        new FormField('level', [FormValidators.REQUIRED]),
    ];
    const history = useHistory();
    const [showQuestionPopUp, setShowQuestionPopUp] = useState<boolean>(false);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [loadingWorksheetQuestions, setLoadingWorksheetQuestions] = useState(false);
    const [testOptions, setTestOptions] = useState<boolean>(false);
    const [testByAI, setTestByAI] = useState<boolean>(false);
    const [questionError, setQuestionError] = useState<string>("");
    const [testSetNumberError, setTestSetNumberError] = useState<string>("");
    const userCode = localStorage.getItem("user_code");
    const [noQuestions, setNoQuestions] = useState<any>("");
    const [selectQuestionType, setSelectQuestionType] = useState<any>("random");

    useEffect(() => {
        getWorksheetData();

    }, [])


    const getWorksheetData = () => {
        setLoading(true);
        GET_WORKSHEET_LIST(activePage == 0 ? activePage : activePage - 1, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, topicUuid, "WORKSHEETS", preparedSearchData).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);

                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setWorksheetList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_WORKSHEET_LIST(pageNumber, boardUuid, mediumUuid, standardUuid, subjectUuid, chapterUuid, topicUuid, "WORKSHEETS", preparedSearchData ? preparedSearchData : {}).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setWorksheetList(data);
                    setLoading(false);
                }
            }
        });
    }
    const onDelete = (data: any) => {
        setLoading(true);
        const id = data.uuid;
        DELETE_WORKSHEET(id).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.err);
                setLoading(false);
            } else {
                setLoading(false);
                toast.success(res.message);
                getWorksheetData();
            }
        });

    }
    const onEdit = (data: any) => {
        setWorksheetUuid(data?.uuid);
        setFormLoading(false);
        setNameError('');
        setLevelError(false);
        setNumberError("");
        setCanShowPopup(true);
        if (data?.uuid) {
            setFormLoading(true);
            GET_WORKSHEET_BY_UUID(data?.uuid).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setFormLoading(false);

                } else {
                    if (res.rows.length > 0) {
                        setNoQuestions(res.rows[0]?.questions);
                        setCurrentFormData(res.rows[0]);
                        setFormLoading(false);

                    }
                }
            });
        }
    }
    const handleClose = () => {
        setFormData({});
        setCurrentFormData({});
        setCanShowPopup(false);
        setShowQuestionPopUp(false);

    }
    const addWorksheet = (data: any) => {
        setTestByAI(false);
        setFormLoading(false);
        setTypeError(false);
        setLevelError(false);
        setCanShowPopup(true);
        setWorksheetUuid(data);
        setNumberError("");
        const datafrom = {
            level: '',
            type: "",
            number: '',
            name: '',
            questions: 30
        };
        setFormData({ ...datafrom });
        setCurrentFormData({ ...datafrom });
        medimForm?.current.reset();
    }
    const handleFormInput = (data: any) => {
        data.value = { ...currentFormData, ...data.value };
        setFormData(data);
        const errors: any = FormValidator(FormValidations, data.value);
        setFormValidationErrors(errors);
    };

    const handleLevelChange = (e: any) => {
        const data = { ...formData.value };
        data.level = e.target.value;
        if (e.target.value) {
            setLevelError(false);
        }
        if (currentFormData) {
            currentFormData.level = e.target.value;
        }
    }
    const handleTestQuestion = (value: any) => {
        const data = { ...formData.value };
        if (value) {
            data.questions = value;
            const re = /^[0-9\b]+$/;
            if (re.test(value)) {
                if (value.length <= 7) {
                    setQuestionError("");
                    setNoQuestions(value);
                    if (testByAI) {
                        getQuestionByAI(false, true, value);
                    }
                } else {
                    setQuestionError("Upto 7 digits only")
                }
            }
            else {
                setQuestionError("Please enter only numbers ")
            }
            if (currentFormData) {
                currentFormData.questions = value;
            }

        } else {
            setQuestionError("Please enter questions");
            if (currentFormData) {
                currentFormData.questions = ""
            }
            data.questions = "";

        }
    }
    const handleSetNumber = (value: any) => {
        const data = { ...formData.value };
        data.set_number = value;
        const re = /^[0-9\b]+$/;
        if (value) {
            if (re.test(value)) {
                if (value.length <= 7) {
                    setTestSetNumberError("");

                } else {
                    setTestSetNumberError("Upto 7 digits only")
                }
            }
            else {
                setTestSetNumberError("Please enter only numbers ")
            }
        } else {
            setTestSetNumberError("Please enter test number ")
        }
        if (currentFormData) {
            currentFormData.set_number = value;
        }

    }

    const createWorksheet = (submitByAI?: boolean) => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        setFormValidationErrors(errors);
        Object.keys(formsData).forEach(function (key) {
            if (typeof formsData[key] === 'string') {
                formsData[key] = formsData[key].trim();
            }
        });
        const payLoad = {
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            topic_uuid: topicUuid,
            uuid: "",
            mode: "ABHYAAS",
            exam_type: "",
            type: "WORKSHEETS",
            level: formsData?.level ? formsData?.level : "",
            number: formsData?.number,
            name: formsData?.name ? formsData?.name : "",
            questions_data: submitByAI ? questionsList : [],
            set_number: formsData?.set_number,
            created_by: userCode,
            questions: Number(formsData?.questions),
            question_count_update: true

        }
        if (formsData?.level && !numberError && formsData?.number && formsData?.questions && !questionError) {
            setFormLoading(true);
            if (submitByAI) {
                CREATE_WORKSHEET(payLoad).then((res: any) => {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setFormLoading(false);
                    } else {
                        toast.success(res?.message);
                        getWorksheetData();
                        setFormLoading(false);
                        setCanShowPopup(false);
                        setFormData({});
                        setCurrentFormData({});

                    }
                });
            } else {
                props?.setTestType("create");
                props?.setCreateTestData(payLoad);
                setTimeout(() => {
                    props.setCanShowTestQuestions(true);
                }, 600);
                setFormLoading(false);
            }

        } else {
            if (!formsData?.questions) {
                setQuestionError("Please enter no of questions in test");
            }
            if (!formsData?.level) {
                setLevelError(true);
            }
            if (!formsData?.number) {
                setNumberError("Please enter worksheet number");

            } else {
                setNumberError(numberError);
            }

        }
    }
    const updateWorksheet = () => {
        const formsData = formData.value ? { ...formData.value } : { ...currentFormData };
        setIsFormSubmitted(true);
        const errors: FormControlError[] = FormValidator(FormValidations, formsData);
        const payLoad = {
            uuid: worksheetUuid,
            board_uuid: boardUuid,
            medium_uuid: mediumUuid,
            standard_uuid: standardUuid,
            subject_uuid: subjectUuid,
            chapter_uuid: chapterUuid,
            topic_uuid: topicUuid,
            mode: "ABHYAAS",
            type: "WORKSHEETS",
            exam_type: "",
            level: formsData?.level ? formsData?.level : "",
            number: formsData?.number,
            name: formsData?.name,
            set_number: formsData?.set_number,

        }
        setFormValidationErrors(errors);
        if (formsData?.level && !numberError && formsData?.number) {
            setFormLoading(true);
            UPDATE_WORKSHEET(payLoad).then((res: any) => {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setFormLoading(false);
                } else {
                    toast.success(res.message);
                    getWorksheetData();
                    setFormLoading(false);
                    setCanShowPopup(false);
                    setFormData({});
                    setCurrentFormData({});
                }
            });
        } else {
            if (!formsData?.level) {
                setLevelError(true);
            }
            if (!formsData?.number) {
                setNumberError("Please enter worksheet number");

            } else {
                setNumberError(numberError);
            }
        }
    }
    const handleSearchInput = (data: any) => {
        setSearchData(data);
    };
    function handleSearch() {
        const data = { ...searchData.value };
        if (data?.search) {
            setLoading(true);
            for (const key of Object.keys(data)) {
                if (data[key] === "") {
                    delete data[key];
                }
            }
            setPreparedSearchData(data);
            let preparedData = {
                board_uuid: boardUuid,
                medium_uuid: mediumUuid,
                standard_uuid: standardUuid,
                subject_uuid: subjectUuid,
                chapter_uuid: chapterUuid,
                topic_uuid: topicUuid,
                mode: "ABHYAAS",
                type: "WORKSHEETS",
                sorting_field: "number",
                search: data?.search,
                sorting_type: "asc"
            }

            GET_WORKSHEET_FILTER(preparedData).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    data?.forEach((element: any) => {
                        element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                        if (element?.modified_dt) {
                            element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                        }
                    });
                    setActivePage(0);
                    setWorksheetList(data);
                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    setLoading(false);
                }
            });
        }
    }
    function handleRefresh() {
        const data = searchData;
        data.value = {};
        setSearchData({ ...data });
        preparedSearchData.search = "";
        preparedSearchData.sorting_field = "number";
        preparedSearchData.sorting_type = "asc";
        preparedSearchData.mode = "ABHYAAS"
        setPreparedSearchData(preparedSearchData);
        searchForm.current.reset();
        getWorksheetData();

    }
    const onActions = (data: any) => {
        if (props.isLinked === "false" && props.selectTest) {
            props.selectTest(data?.data?.uuid);
        }

    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    };
    const viewQuestion = (data: any) => {
        setTestByAI(false);
        setShowQuestionPopUp(true);
        setLoadingWorksheetQuestions(true);
        setQuestionsList(data?.questions_data);
        setLoadingWorksheetQuestions(false);
    }

    const handleTestNumber = (value: any) => {
        const data = { ...formData.value };
        data.number = value;
        const re = /^[0-9\b]+$/;
        if (re.test(value)) {
            setNumberError("")
        }
        else {
            setNumberError("Please enter only numbers")
        }
        if (currentFormData) {
            currentFormData.number = value;
        }
    }
    const handleByAI = () => {
        setNoQuestions(30);
        setQuestionError("");
        getTestNumbersData(true, 30);
    }
    const handleSubmitByAI = () => {
        createWorksheet(true);
    }
    const getQuestionByAI = async (uniq: boolean, rand: boolean, limit: any) => {
        try {
            const payLoad = {
                board_uuid: props?.filterData?.board_uuid,
                medium_uuid: props?.filterData?.medium_uuid,
                standard_uuid: props?.filterData?.standard_uuid,
                subject_uuid: props?.filterData?.subject_uuid,
                chapter_uuid: props?.filterData?.chapter_uuid,
                topic_uuid: props?.filterData?.topic_uuid,
                unique: uniq,
                random: rand,
                mode_type: "1,3",
                validation_status: "VERIFIED"
            }
            GET_CONTENT_RANDOM_QUESTIONS(payLoad, 0, Number(limit)).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.err);
                        setLoading(false);
                    } else {
                        const data = res;
                        if (data?.all_questions && data?.all_questions?.length > 0) {
                            const preQuestions: any = data?.all_questions?.map((item: any) => ({
                                ...item,
                                mode: "add"
                            }));
                            setQuestionsList(preQuestions);
                        } else {
                            toast.error(res?.message);
                            setQuestionsList([]);
                        }
                        setTestByAI(true);
                        setCanShowPopup(true);
                    }
                }
            });
        } catch (error) {

        }
    }
    const createTestManually = () => {
        setNoQuestions("");
        setQuestionError("");
        addWorksheet("0");
        getTestNumbersData(false);
    }
    const getTestNumbersData = async (aI: boolean, limit?: any) => {
        try {
            const payLoad = {
                "board_uuid": filterData?.board_uuid,
                "medium_uuid": filterData?.medium_uuid,
                "standard_uuid": filterData?.standard_uuid,
                "subject_uuid": filterData?.subject_uuid,
                "chapter_uuid": filterData?.chapter_uuid,
                "topic_uuid": filterData?.topic_uuid,
                "type": "WORKSHEETS",
            }
            setFormLoading(true);
            setQuestionsList([]);
            const res: any = await GET_TEST_DATA(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                } else {
                    const preData = currentFormData;
                    preData.number = res?.next_test_number;
                    preData.set_number = res?.next_set_number;
                    preData.level = "EASY"
                    preData.time = 60;
                    setCurrentFormData({ ...preData });
                    if (aI) {
                        getQuestionByAI(false, true, Number(limit));
                    }
                }
            }
            setFormLoading(false);
        } catch (error) {
            setFormLoading(false);
        }
    }
    // const handleQuestionChanges = (e: any, type: string) => {
    //     setSelectQuestionType(type);
    //     const question = e.target.value;
    //     if (type === "unique") {
    //         getQuestionByAI(true, false, Number(noQuestions));
    //     } else {
    //         getQuestionByAI(false, true, Number(noQuestions));
    //     }
    // }
    const handleQuestionChanges = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        setSelectQuestionType(type); // Update state first
        getQuestionByAI(type === "unique", type === "random", Number(noQuestions));
    };
    const closePopup = () => {
        if (testByAI) {
            setShowQuestionPopUp(false);
            setCanShowPopup(true);
        } else {
            setShowQuestionPopUp(false)
        }
    }
    const handlePreview = () => {
        setShowQuestionPopUp(true);
        setCanShowPopup(false);
    }
    return (
        <div>
            {showQuestionPopUp &&
                <div className={`${!showQuestionPopUp && "d_none"}`}>
                    <ContentPreviewQuestions questionsList={questionsList} setShowPopUp={setShowQuestionPopUp} topic_questions={false} handleClose={closePopup} />
                </div>
            }
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog parent-model-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {testByAI ? <span>Create Worksheet by AI</span> : <span>
                                    {worksheetUuid === "0" ?
                                        "Create Worksheet Manually"
                                        : " Update Worksheet Manually"
                                    }
                                </span>}
                            </h5>

                            <button
                                type="button"
                                className="close popup-close cursor-pointer"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={handleClose}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body parent-info ">
                            { }
                            {formLoading && (
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            )}
                            {!formLoading && (
                                <>
                                    <FormBuilder onUpdate={handleFormInput}>
                                        <form ref={medimForm}>
                                            <div className="row custom-form">
                                                <div className="col-md-4">
                                                    <div className='mb-4'>
                                                        <label>Board</label>
                                                        <select name="board_uuid" id="board_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.board_name} >
                                                            <option value={filterData?.board_uuid}>{filterData?.board_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4 position-relative'>
                                                        <label>Medium</label>
                                                        <select name="medium_uuid" id="medium_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.medium_name} >
                                                            <option value={filterData?.medium_uuid}>{filterData?.medium_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Standard</label>
                                                        <select name="standard_uuid" id="standard_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.standard_name}>
                                                            <option value={filterData?.standard_uuid}>{filterData?.standard_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Subject</label>
                                                        <select name="subject_uuid" id="subject_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.subject_name} >
                                                            <option value={filterData?.subject_uuid}>{filterData?.subject_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Chapter</label>
                                                        <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.chapter_name}>
                                                            <option value={filterData?.chapter_uuid}>{filterData?.chapter_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className='mb-4  position-relative'>
                                                        <label>Topic</label>
                                                        <select name="topic_uuid" id="topic_uuid" className="form-select ft-14 disabled_bg select_icon_remove" disabled value={filterData?.topic_name}>
                                                            <option value={filterData?.topic_uuid}>{filterData?.topic_name}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Name</label>
                                                        <input className="form-control form-control-lg ft-14" type="text" name="name" defaultValue={currentFormData?.name} placeholder="Please enter worksheet name" />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Worksheet Number </label>
                                                        <input className="form-control form-control-lg ft-14 disabled_bg" disabled type="text" name="number" value={currentFormData?.number} placeholder="Please enter number" onChange={(e) => { handleTestNumber(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="mb-4">
                                                        <label className="form-label ">Difficulty Level </label>
                                                        <select name="level" id="level" className="form-select ft-14" onChange={(e) => { handleLevelChange(e) }} value={currentFormData?.level} >
                                                            <option value="EASY">Easy</option>
                                                            <option value="MEDIUM">Medium</option>
                                                            <option value="HIGH">Hard</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Total Questions<span className="f12 ms-1 fw-normal">(in test)</span><sup className="text-danger f16">*</sup></label>
                                                        <input className="form-control form-control-lg ft-14" type="text" name="questions" defaultValue={noQuestions} placeholder="Please enter no of questions in worksheet" onChange={(e) => { handleTestQuestion(e.target.value) }} />
                                                        {(questionError) && <p className="text-danger">{questionError}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-md-4 ">
                                                    <div className="mb-4">
                                                        <label className="form-label mb-1">Worksheet - Set number <sup className="text-danger f14"></sup></label>
                                                        <input className="form-control form-control-lg f16 disabled_bg " disabled type="text" name="set_number" defaultValue={currentFormData?.set_number} placeholder="Please enter set number" onChange={(e) => { handleSetNumber(e.target.value) }} />
                                                    </div>
                                                </div>
                                                {testByAI &&
                                                    <div className="col-md-4 ">
                                                        <div className="mb-4">
                                                            <label className="form-label mb-3">Allow AI to add <sup className="text-danger f14"></sup></label>
                                                            <div className="d-flex justify-content-between mx-1 form-group">
                                                                <p>
                                                                    <input className="me-2 pt-1 cursor-pointer" type="radio" id="unique" name="radio-group" value="unique"
                                                                        checked={selectQuestionType === "unique"} onChange={(e) => { handleQuestionChanges(e, "unique") }} />
                                                                    <label className="fw-normal f14">Unique Questions</label>
                                                                </p>
                                                                <p>
                                                                    <input className="me-2 pt-1  cursor-pointer" type="radio" id="random" name="radio-group" value="random"
                                                                        checked={selectQuestionType === "random"} onChange={(e) => { handleQuestionChanges(e, "random") }} />
                                                                    <label className="fw-normal f14">Repeat Questions</label>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </form>
                                    </FormBuilder>
                                </>
                            )}
                        </div>
                        <div className="modal-footer">
                            {testByAI ?
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary px-4 rounded-3 cursor-pointer py-1 f12"
                                        data-dismiss="modal"
                                        onClick={handleClose}
                                    >
                                        Back
                                    </button>
                                    <button className="btn text-white f12 px-4 py-1 rounded-3 payment_user_btn border-0 mx-3" onClick={handlePreview} disabled={questionsList?.length == 0}>Preview</button>
                                    < button className="btn btn-primary  px-4 rounded-3 cursor-pointer py-1 f14" onClick={handleSubmitByAI} disabled={questionsList?.length == 0} >Create Worksheet </button>
                                </div>
                                : <div>
                                    {worksheetUuid === "0" && < a className="btn btn-primary btn-sm px-4 rounded-3 cursor-pointer py-1 f14" onClick={() => createWorksheet()}>Create Workheet</a>}
                                    {worksheetUuid !== "0" && <a className="btn btn-primary btn-sm px-4 rounded-3 cursor-pointer py-1 f14" onClick={() => updateWorksheet()}>Update Workheet</a>}
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-sm px-4 rounded-3 cursor-pointer py-1 f14 ms-4"
                                        data-dismiss="modal"
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </button>
                                </div>}

                        </div>
                    </div>
                </div>
            </div>

            <div className="row  mt-3" style={{ marginBottom: '15px' }}>
                <div className="col-md-4">
                    <FormBuilder onUpdate={handleSearchInput}>
                        <form ref={searchForm}>
                            <input type="text" id="search" className="form-control ft-14" placeholder="search" name="search" onKeyDown={(e) => handleKeyDown(e)} />
                        </form>
                    </FormBuilder>
                </div>
                <div className="col-md-4 pt-3 pt-md-0 ">
                    <button className="btn btn-primary px-3 cursor-pointer" type="button" onClick={() => handleSearch()}>Search</button>&nbsp;
                    <button className="btn clear-btn cursor-pointer" type="button" onClick={() => handleRefresh()}>
                        Clear
                    </button>
                </div>

                <div className="col-md-4 pt-3 pt-md-0 text-end ">
                    <div className='export_btn me-2 me-sm-4 rounded-12' style={{ position: 'relative', right: '75px', top: '-75px', height: '35px' }}>
                        <button
                            className="export_btn_select f14"
                            onClick={() => setTestOptions(!testOptions)}
                        >
                            <span>Create Worksheet
                                <i className='bi bi-chevron-down ps-3'></i></span>
                        </button>
                        {testOptions && <ul className='export_btn_select_drop_list z_100'>
                            <li className='text-start export_btn-list-view' onClick={createTestManually}>
                                <span>Manually</span></li>
                            <li className='export_btn-list-view' onClick={handleByAI}>
                                <span>By AI/System</span></li>
                        </ul>}
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="mt-3">
                    <DataTable TableCols={ContentAbhyasWorksheetDataGridCols} tableData={worksheetList}
                        onActions={(e) => onActions(e)}
                        onChange={viewQuestion}
                        editInfo={onEdit}
                        deleteInfo={onDelete}
                        pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                </div>}

        </div>
    )
}

