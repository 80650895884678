import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_BOARD_FILTER_LIST = (): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/boards`);
};

export const GET_MEDIUM_FILTER_LIST = (boardUuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/mediums?board_uuid=${boardUuid}`);
};
export const GET_STANDARD_FILTER_LIST = (mediumUuid: string): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/standards?medium_uuid=${mediumUuid}&sorting_type=ASC&sorting_field=standard`
  );
};

export const GET_SUBJECT_FILTER_LIST = (standardUuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/subjects?standard_uuid=${standardUuid}`);
};

export const GET_CHAPTER_FILTER_LIST = (sujectUuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/chapters?subject_uuid=${sujectUuid}`);
};

export const GET_TOPIC_FITER_LIST = (chapterUuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/topics?chapter_uuid=${chapterUuid}`);
};
