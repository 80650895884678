import API from "../utility/axios";
const apiUrl = "/api/v2";

export const GET_BOARD_FILTERS_LIST = (): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/boards`);
};

export const GET_MEDIUM_FILTERS_LIST = (boardUuid: string): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/mediums?board_uuid=${boardUuid}`);
};
export const GET_STANDARD_FILTERS_LIST = (
  boardUUid: string,
  mediumUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/standards?board_uuid=${boardUUid}&medium_uuid=${mediumUuid}&sorting_type=ASC&sorting_field=standard`
  );
};

export const GET_SUBJECT_FILTERS_LIST = (
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/subjects?board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}`
  );
};

export const GET_CHAPTER_FILTERS_LIST = (
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  sujectUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/chapters?board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${sujectUuid}`
  );
};

export const GET_TOPIC_FITERS_LIST = (
  boardUuid: string,
  mediumUuid: string,
  standardUuid: string,
  subjectUuid: string,
  chapterUuid: string
): Promise<any> => {
  return API.get(
    `${apiUrl}/abhyaas/topics?board_uuid=${boardUuid}&medium_uuid=${mediumUuid}&standard_uuid=${standardUuid}&subject_uuid=${subjectUuid}&chapter_uuid=${chapterUuid}`
  );
};
export const GET_TOPIC_DATA_LIST = (data: any): Promise<any> => {
  return API.get(`${apiUrl}/abhyaas/topics`, {
    params: data,
  });
};
