import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GET_MATHS_MODULE_REPORT_BY_STUDENT } from "../../../../app/service/mns-service";
import { toast } from "react-toastify";
import { FILE_REPORT_DOWNLOAD, MATHS_REPORT_DOWNLOAD } from "../../../../app/service/maths-reports";
import MathsReportData from "../../../../components/maths-reports/view-report";
import { MNSReport } from "../../../../components/maths-reports/mns";
import { MLAOneReport } from "../../../../components/maths-reports/mla-one";
import { MLATwoReport } from "../../../../components/maths-reports/mla-two";
import axios from "axios";

const ParentViewStudentMathsReport = () => {
    const [loading, setLoading] = useState(false);
    let { userCode } = useParams<{ userCode: string }>();
    const [reportInfo, setReportInfo] = useState<any>({});
    const pdfRef = useRef<HTMLDivElement>(null);
    const currentUrl = window.location.href.split('#')[0];

    useEffect(() => {
        getReports();
    }, []);
    const getReports = () => {
        setLoading(true);
        GET_MATHS_MODULE_REPORT_BY_STUDENT(userCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    setReportInfo(res);
                    setLoading(false);
                }

            }
        })
    }
    const downLoadLinks = async () => {
        setLoading(true);
        try {
            const res = await handleClick();
            await handleDownloadlink(res?.url);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    // const handleClick = () => {
    //     let payLoad = {
    //         url: `${currentUrl}#/parent-dashboard/view/maths/reports/student/${userCode}`,
    //         user_code: userCode,
    //         // token: localStorage.getItem("token")
    //     }
    //     setLoading(true);
    //     MATHS_REPORT_DOWNLOAD(payLoad).then((res: any) => {
    //         if (res) {
    //             if (res.status === 'fail') {
    //                 toast.error(res.message);
    //                 setLoading(false);
    //             } else {
    //                 setLoading(false);
    //                 if (res?.complete_url) {
    //                     handleDownload(res?.complete_url);

    //                 }

    //             }

    //         }
    //     })

    // };

    const handleClick = async () => {
        let payLoad = {
            url: `${currentUrl}#/parent-dashboard/view/maths/reports/student/${userCode}`,
            user_code: userCode,
            // token: localStorage.getItem("token")
        }
        try {
            const res: any = await MATHS_REPORT_DOWNLOAD(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (res?.complete_url) {
                        return res;
                    } else {
                        return "";
                    }

                }

            }
        } catch (error) {
        }
    };

    const handleDownloadlink = async (urlLink: string) => {
        if (urlLink) {
            let payLoad = {
                url: urlLink,
            }
            try {
                const res: any = await FILE_REPORT_DOWNLOAD(payLoad);
                if (res) {
                    window.open(res, '_blank');
                }

            } catch (error) {

            } finally {

            }
        }

    };
    return (
        <>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><span>Maths Report</span></li>
                    </ol>
                </nav>
                <div className="mt-3 text-end me-5">
                    <button className="btn btn-primary px-3 py-1" id="MATH_REPORT_DOWNLOAD" onClick={downLoadLinks}>Download </button>
                </div>
                <div ref={pdfRef} id="download-content">
                    <div className="report_border mx-lg-5 mx-2 mt-3 mb-5 px-lg-4 px-1 py-3">
                        <div className="d-flex justify-content-end "><i className="report_ias_icon " style={{ width: "80px" }}></i></div>
                        <MathsReportData reportInfo={reportInfo} />
                        <div className="mx-lg-3">
                            <MNSReport mnsReportInfo={reportInfo?.mnsDetails} />
                            <MLAOneReport mlaOneReportInfo={reportInfo?.mlaOneDetails} />
                            <MLATwoReport mlaTwoReportInfo={reportInfo?.mlaTwoDetails} />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    )
}
export default ParentViewStudentMathsReport;